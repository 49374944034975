<template>
  <Surface>
    <Page
      v-if="tag"
      :header="tag.name"
      :useBackToTop="true"
    >
      <template #context>
        <TagActions
          :tag="tag"
        />
      </template>
      <template
        v-if="hasTitles"
        #toolbar
      >
        <FilterButton
          :options="availableSubjects"
          filterType="subject"
          :iconOnly="mobile"
          @input="updatePath"
        />
        <TextFilter
          v-model="textFilter"
          :class="$style.textFilter"
          :label="$t('tag.filters.text.placeholder')"
        />
      </template>
      <template #default>
        <template v-if="hasTitles">
          <div
            v-if="subjectFilteredTitles.length"
            :class="$style.count"
          >
            <p>
              {{ showingCount }}
            </p>
          </div>
          <ol
            v-if="textAndSubjectFilteredTitles.length"
            :class="$style.cardList"
          >
            <li
              v-for="item in textAndSubjectFilteredTitles"
              :key="item.slug"
            >
              <TitleCard
                :title="item"
              />
            </li>
          </ol>
          <EmptyState
            v-else
            :header="$t('tag.filters.empty.header')"
            :content="$t('tag.filters.empty.content')"
          />
        </template>
        <EmptyState
          v-else
          :header="$t('tag.empty.header')"
          :content="$t('tag.empty.content')"
        />
      </template>
    </Page>
    <NotFound
      v-else
      :messageText="$t('tag.error.missing')"
    />
  </Surface>
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import { Constants } from 'app/base/constants';
import EmptyState from 'app/components/EmptyState.vue';
import FilterButton from 'app/components/FilterButton.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import TagActions from 'app/components/TagActions.vue';
import TextFilter from 'app/components/TextFilter.vue';
import TitleCard from 'app/components/cards/TitleCard.vue';
import { watchResultCount } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { usePatron } from 'app/functions/use-patron';
import { SubjectFilters, getQueryParametersFromSubjectFilters, useSubjectFilters } from 'app/functions/use-subject-filters';
import { useTextFilter } from 'app/functions/use-text-filter';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { Title, TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import NotFound from 'app/views/NotFound.vue';
import { PropType, Ref, computed, defineComponent, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'TagDetails',
  components: {
    EmptyState,
    FilterButton,
    NotFound,
    Page,
    Surface,
    TagActions,
    TextFilter,
    TitleCard
  },
  props: {
    tagSlug: {
      type: String,
      required: true
    },
    subjectFilters: {
      type: Object as PropType<SubjectFilters>,
      default: {} as SubjectFilters
    }
  },
  setup: (props, ctx) => {
    const helpLink = Constants.HELP_PATHS.TAGS;
    const isEditing = ref(false);
    const { tags } = usePatron();
    const tag = computed(() => {
      return tags.value.find((ta) => ta.slug === props.tagSlug);
    });

    const { windowWidth } = useWindowSize();
    const mobile = computed(() => windowWidth.value <= Breakpoint.VeryWide);

    const items = ref<TitleRecord[]>([]);

    const { availableSubjects, filteredTitles: subjectFilteredTitles } = useSubjectFilters(items as Ref<TitleRecord[]>, computed(() => props.subjectFilters));

    const {
      textFilter,
      filteredItems: textAndSubjectFilteredTitles
    } = useTextFilter(subjectFilteredTitles, Title.FILTER_FUNCTIONS.filterByText);

    watchEffect(async () => {
      if (tag.value) {
        const titleIds = tag.value.all
          .slice()
          .reverse()
          .map((ta) => ta.titleSlug);

        items.value = await APP.titleCache.getFreshTitles(titleIds);
      }
    });

    const { t } = useI18n();

    const showingCount = computed(() => {

      return t(
        'tag.showingCount',
        { COUNT: textAndSubjectFilteredTitles.value.length, TOTAL: subjectFilteredTitles.value.length });
    });
    watchResultCount(showingCount);

    const router = useRouter();
    const updatePath = (filters: SubjectFilters, tab: string) => {
      const newRoute = {
        name: RouteName.TagDetails,
        query: {
          ...getQueryParametersFromSubjectFilters(filters)
        }
      };

      router.replace(newRoute);
    };

    const hasTitles = computed(() => items.value.length > 0);

    return {
      availableSubjects,
      hasTitles,
      helpLink,
      isEditing,
      mobile,
      showingCount,
      tag,
      subjectFilteredTitles,
      textAndSubjectFilteredTitles,
      textFilter,
      updatePath
    };
  }
});
</script>

<style module>

  .text-filter {
    margin-left: auto;
    max-width: 16rem;
  }

  .edit-tag-button {
    margin-left: auto;
  }

  .list-container {
  flex: 1;
  background-color: var(--c-white);
  border-radius: .625rem .625rem .5rem .5rem;
  }

  .card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--px-card-min-width), 1fr));
    grid-gap: 1.5rem;
    flex: 1;
  }

  .count {
    width: fit-content;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 499px /* px-vp-narrow */) {
    .toolbar {
      padding: 0.75rem 1.25rem;
    }

    .card-list {
      grid-template-columns: 1fr;
    }
  }
</style>
