import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingPage = _resolveComponent("LoadingPage")!
  const _component_NotFound = _resolveComponent("NotFound")!
  const _component_FilterButton = _resolveComponent("FilterButton")!
  const _component_TextFilter = _resolveComponent("TextFilter")!
  const _component_AnnotationDetailsCopyContent = _resolveComponent("AnnotationDetailsCopyContent")!
  const _component_AnnotationDetailsContent = _resolveComponent("AnnotationDetailsContent")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Page = _resolveComponent("Page")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      (_ctx.state.status === 'loading')
        ? (_openBlock(), _createBlock(_component_LoadingPage, { key: 0 }))
        : (_ctx.state.status === 'notfound')
          ? (_openBlock(), _createBlock(_component_NotFound, { key: 1 }))
          : (_openBlock(), _createBlock(_component_Page, {
              key: 2,
              header: _ctx.state.pageTitle,
              subtitle: _ctx.state.subtitle,
              title: _ctx.state.title,
              useBackToTop: true
            }, {
              toolbar: _withCtx(() => [
                _createVNode(_component_FilterButton, {
                  options: _ctx.filterObjects,
                  filterType: "annotation",
                  iconOnly: _ctx.mobile,
                  onInput: _ctx.updatePath
                }, null, 8, ["options", "iconOnly", "onInput"]),
                _createVNode(_component_TextFilter, {
                  modelValue: _ctx.textFilter,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textFilter) = $event)),
                  label: _ctx.$t('annotations.textFilter'),
                  class: _normalizeClass(_ctx.$style.textFilter)
                }, null, 8, ["modelValue", "label", "class"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_AnnotationDetailsCopyContent, { titleSlug: _ctx.titleSlug }, null, 8, ["titleSlug"]),
                _createVNode(_component_AnnotationDetailsContent, {
                  annotations: _ctx.detailsAnnotations,
                  title: _ctx.state.title.title,
                  subtitle: _ctx.state.title.subtitle,
                  mobile: _ctx.mobile,
                  displayCounts: _ctx.displayCounts,
                  selectAllState: _ctx.selectAllState,
                  focusTargetId: _ctx.pagingFocusTargetId,
                  "onAnnotations:update": _ctx.onSelectedUpdate,
                  "onAnnotations:update:all": _ctx.onSelectedUpdateAll
                }, null, 8, ["annotations", "title", "subtitle", "mobile", "displayCounts", "selectAllState", "focusTargetId", "onAnnotations:update", "onAnnotations:update:all"]),
                (_ctx.totalPages > 1)
                  ? (_openBlock(), _createBlock(_component_Pagination, {
                      key: 0,
                      currentPage: _ctx.currentPage,
                      "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
                      class: _normalizeClass(_ctx.$style.pagination),
                      totalPages: _ctx.totalPages
                    }, null, 8, ["currentPage", "class", "totalPages"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header", "subtitle", "title"]))
    ], undefined, true),
    _: 1
  }))
}