<template>
  <div :class="$style.page">
    <PageHeaderTitle
      v-if="title"
      ref="pageHeader"
      :class="$style.header"
      :header="header"
      :subtitle="subtitle"
      :title="title"
    />
    <PageHeaderBasic
      v-else
      ref="pageHeader"
      :class="$style.header"
      :header="header"
      :subtitle="subtitle"
    >
      <template #context>
        <slot name="context"></slot>
      </template>
      <template #subheader>
        <slot name="subheader"></slot>
      </template>
    </PageHeaderBasic>
    <div
      v-if="$slots.toolbar"
      :class="$style.toolbar"
    >
      <slot name="toolbar"></slot>
    </div>
    <div
      :class="$style.content"
    >
      <slot></slot>
    </div>
    <BackToTopButton
      :show="showBackToTop && useBackToTop"
      :scrollTo="pageHeaderEl"
      :focusOn="focusOn"
      @show="(shouldShow) => showBackToTop = shouldShow"
    />
  </div>
</template>

<script lang="ts">
import BackToTopButton from 'app/components/BackToTopButton.vue';
import PageHeaderBasic from 'app/components/PageHeaderBasic.vue';
import PageHeaderTitle from 'app/components/PageHeaderTitle.vue';
import { Title } from 'app/models/title';
import { computed, defineComponent, PropType, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    PageHeaderBasic,
    PageHeaderTitle,
    BackToTopButton
  },
  props: {
    header: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    },
    title: {
      type: Object as PropType<Title>,
      default: undefined
    },
    useBackToTop: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, ctx) => {
    const pageHeader = ref<InstanceType<typeof PageHeaderTitle | typeof PageHeaderBasic> | null>(null);
    const pageHeaderEl = computed(() => pageHeader.value && pageHeader.value.$el);

    const showBackToTop = ref(false);
    // use breadcrumb as the top of the main section to focus on
    // for top level pages without breadcrumbs, use user menu button to focus on
    const route = useRoute();
    const focusOn = route.meta.top ? '[data-menu_focus]' : '[data-breadcrumb_focus]';

    return {
      pageHeader,
      pageHeaderEl,
      showBackToTop,
      focusOn
    };
  }
});
</script>

<style module>

.page {
  /* We want the header and content sections to extend to the edge of the displayed area,
   * so we don't want to pad the page directly.
   * But we want them to pad content from the edges consistently, so use CSS variables.
   */
  --page-padding-horizontal: 2rem;
  --page-padding-vertical: 2rem;

  display: flex;
  flex-direction: column;
  flex: 1;
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .page {
    --page-padding-horizontal: 1rem;
    --page-padding-vertical: 2rem;
  }
}

.header {
  background-color: var(--c-dark-blue);
  color: var(--c-white);
  padding: var(--page-padding-vertical) var(--page-padding-horizontal) 1.25rem;
  border-radius: .5rem .5rem 0 0;
}

.toolbar {
  background: var(--c-lightest-gray);
  box-shadow: 0px 1px 6px 0px var(--c-shadow);
  padding: 1.25rem var(--page-padding-horizontal);
  display: flex;
  gap: 10px;
}

.content {
  padding: var(--page-padding-vertical) var(--page-padding-horizontal);
  background-color: var(--c-white);
  border-radius: 0 0 .5rem .5rem;
  flex: 1;
}
</style>
