<template>
  <PromptDialog
    ref="prompt"
    :heading="$t('annotationCopying.prompt.copy.header')"
    :dismissible="false"
    @submit="startCopying"
    @close="$emit(onCloseEvent)"
  >
    <CopyContent
      :id="copyPromptId"
      :targetTitle="(targetTitle as TitleRecord)"
      :sourceTitle="(sourceTitle as TitleRecord)"
      :sourceAnnotations="sourceAnnotations"
    />
    <div
      :class="$style.actions"
    >
      <FormSubmitButton
        autofocus
        :label="$t('annotationCopying.prompt.copy.continue')"
        :enabled="true"
        size="small"
      />

      <button
        :class="$style.cancel"
        type="button"
        @click="openBook"
      >
        {{ $t('annotationCopying.prompt.copy.cancel') }}
      </button>
    </div>
  </PromptDialog>
</template>

<script lang="ts">
import CopyContent from 'app/components/CopyContent.vue';
import { startCopy } from 'app/functions/use-annotation-copying';
import { Annotation } from 'app/models/annotation';
import { TitleRecord } from 'app/models/title';
import { generateUUID } from 'lib/common';
import { defineComponent, PropType, ref } from 'vue';
import FormSubmitButton from './FormSubmitButton.vue';
import PromptDialog from './dialogs/PromptDialog.vue';

export default defineComponent({
  name: 'CopyingPrompt',
  components: {
    CopyContent,
    FormSubmitButton,
    PromptDialog
  },
  props: {
    targetTitle: {
      type: Object as PropType<TitleRecord>,
      default: undefined
    },
    sourceTitle: {
      type: Object as PropType<TitleRecord>,
      default: undefined
    },
    sourceAnnotations: {
      type: Array as PropType<Annotation[]>,
      default: () => []
    }
  },
  emits: [
    'copy',
    'open'
  ],
  setup: (props, ctx) => {
    const prompt = ref<InstanceType<typeof PromptDialog> | null>(null);
    const copyPromptId = ref(`copy-explanation-${generateUUID()}`);
    const onCloseEvent = ref<'open' | 'copy'>('open');

    const startCopying = async () => {
      await startCopy(props.targetTitle!.slug, props.sourceTitle!.slug);
      onCloseEvent.value = 'copy';
      prompt.value?.closeModal();
    };

    const openBook = () => {
      onCloseEvent.value = 'open';
      prompt.value?.closeModal();
    };

    return {
      copyPromptId,
      onCloseEvent,
      prompt,
      openBook,
      startCopying
    };
  }
});
</script>

<style module>
.actions {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  align-items: center;
}

.cancel {
  text-transform: uppercase;
  color: var(--c-medium-black);
  composes: linked from global;
}
</style>
