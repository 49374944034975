<template>
  <nav :class="$style.container">
    <div class="notch"></div>
    <router-link
      :to="{ name: targets.home.route }"
      :class="$style.logoLink"
    >
      <div :class="$style.logo">
        <LibraryLogo />
      </div>
    </router-link>
    <ul>
      <li
        v-for="(target, name) in targets"
        :key="name"
      >
        <router-link
          :to="{ name: target.route }"
          :class="[$style.link, active === name ? $style.active : '']"
        >
          <Icon
            :name="target.icon"
            :class="$style.icon"
          />
          {{ $t(target.label) }}
          <p
            v-if="'badge' in target && target.badge"
            class="badge"
          >
            {{ target.badge }}
          </p>
        </router-link>
      </li>
    </ul>


    <transition
      :enterActiveClass="$style.upEnterFrom"
      :leaveActiveClass="$style.upLeaveTo"
      :appear="true"
    >
      <SurveyCallout
        v-if="false"
        :class="$style.survey"
        @close="$emit('close-survey')"
      />
    </transition>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, reactive, watchEffect } from 'vue';
import { RouteName } from 'app/router/constants';
import LibraryLogo from 'app/components/LibraryLogo.vue';
import SurveyCallout from 'app/components/SurveyCallout.vue';
import { useRoute } from 'vue-router';
import { usePatron } from 'app/functions/use-patron';
import { injectNtcContext } from 'app/contexts/ntc-context';

type NavLink = {
  route: RouteName;
  label: string;
  icon: string;
  badge?: string;
};

type NavTargets = {
  home: NavLink;
  browse: NavLink;
  mybooks: NavLink;
  notes: NavLink;
  export: NavLink;
  tags: NavLink;
  ntc?: NavLink;
};

export default defineComponent({
  name: 'NavigationSidebar',
  components: {
    LibraryLogo,
    SurveyCallout
  },
  props: {
    showSurvey: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'close-survey'
  ],
  setup: (props, ctx) => {
    const { exportQueue } = usePatron();
    const exportQueueLength = computed(() => {
      return exportQueue.value.length > 99
        ? '99+'
        : exportQueue.value.length === 0
          ? ''
          : exportQueue.value.length.toString();
    });

    const targets: NavTargets = reactive({
      home: {
        route: RouteName.Home,
        label: 'nav.link.home',
        icon: 'nav-home'
      },
      browse: {
        route: RouteName.Browse,
        label: 'nav.link.browse',
        icon: 'nav-browse'
      },
      mybooks: {
        route: RouteName.MyBooks,
        label: 'nav.link.mybooks',
        icon: 'nav-mybooks'
      },
      notes: {
        route: RouteName.Notes,
        label: 'nav.link.notes',
        icon: 'nav-notes'
      },
      export: {
        route: RouteName.Export,
        label: 'nav.link.export',
        icon: 'nav-export',
        badge: exportQueueLength
      },
      tags: {
        route: RouteName.Tags,
        label: 'nav.link.tags',
        icon: 'nav-tags'
      }
    });

    const { ntcAvailable } = injectNtcContext();

    watchEffect(() => {
      if (ntcAvailable.value) {
        targets.ntc = {
          route: RouteName.Ntc,
          label: 'nav.link.ntc',
          icon: 'nav-ntc'
        };
      }
    });

    const route = useRoute();
    const active = ref(route.meta?.timeline);
    watch(route, (to) => active.value = to?.meta?.timeline);

    return {
      active,
      exportQueueLength,
      targets
    };
  }
});
</script>

<style lang="less" module>
@import '../../app/views/core/base.less';

.container {
  padding: 0.625rem 1.25rem;
  background-color: @c-bg;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
}

.link {
  display: flex;
  align-items: center;

  padding: 0.625rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;

  color: @c-light-black;

  .focus-outline;

  :global(.badge) {
    color: inherit;
    font-weight: inherit;
  }

  @shadow-hover: rgba( 34, 34, 34, 0.10) 0px 1px 6px 0px,
      rgba( 34, 34, 34, 0.05) 5px 5px 25px 0px,
      rgba( 0,0,0, 0.05) 1px 1px 5px 0px;

  &:hover, &:focus, &.active {
    background-color: @c-white;
    box-shadow: @shadow-hover;
  }

  &:focus {
    box-shadow: @shadow-hover, @shadow-focus;
  }

  &.active {
    color: @c-active;
    font-weight: bold;

    :global(.badge) {
      background-color: lighten(@c-primary-red, 40%);
    }
  }
}

.icon {
  fill: currentColor;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.25rem;
}

.logo-link {
  display: block;
  border-radius: 0.25rem;
  margin-bottom: 1rem;

  .focus-outline;
}

.logo {
  margin: 1.75rem auto;
  font-weight: bold;
}

.survey {
  position: sticky;
  bottom: 0;
  margin-bottom: -0.625rem;
}

.up-enter-from,
.up-leave-to {
  transform: translateY(100%);
}
</style>

