<template>
  <dialog
    ref="dialog"
    :class="[$style.dialog, { [$style.animationOpen]: animationOpen }]"
    :style="styles"
    :aria-label="heading"
    @cancel="closeIfAllowed"
    @click="closeIfAllowed"
    @keydown.esc="closeIfAllowed"
  >
    <div
      :class="$style.content"
      @click="(e) => e.stopPropagation()"
    >
      <div :class="$style.headerBar">
        <h2
          :class="[$style.heading, headingClass]"
        >
          {{ heading }}
        </h2>
        <button
          v-if="dismissible"
          :class="$style.dismiss"
          :aria-label="$t('general.close')"
          @click="closeDialog"
        >
          <Icon name="dismiss" />
        </button>
      </div>
      <slot></slot>
    </div>
  </dialog>
</template>

<script lang="ts">
import { useDialog } from 'app/functions/use-dialog';
import { defineComponent, SetupContext } from 'vue';

export default defineComponent({
  props: {
    heading: {
      type: String,
      required: true
    },
    headingClass: {
      type: String,
      default: undefined
    },
    /* Controls whether the escape key and clicking the backdrop close the dialog.
     * In most cases, just let this default to 'true' for better usability.
     */
    dismissible: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx: SetupContext) => {
    const animationDurationMs = 300;

    const {
      animationOpen,
      closeDialog,
      dialog,
      styles
    } = useDialog(ctx, animationDurationMs);

    const closeIfAllowed = (e: Event) => {
      e.preventDefault();

      if (props.dismissible) {
        closeDialog();
      }
    };

    return {
      animationOpen,
      closeDialog,
      closeIfAllowed,
      dialog,
      styles
    };
  }
});
</script>

<style module>
.dialog {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  width: calc(min(600px, 90vw));
  border-radius: var(--form-border-radius);
  box-shadow: 0 2px 8px var(--c-darkest-gray);
}

.dialog::backdrop {
  background-color: rgba(0.2, 0.2, 0.2, 0.2);
}

.content {
  padding: 1rem;
}

.headerBar {
  display: grid;
  grid-template-areas: "heading dismiss";
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.25rem;
}

.heading {
  grid-area: heading;
  font-size: var(--fs-body);
  font-weight: var(--fw-medium);
}

.dismiss {
  grid-area: dismiss;
  line-height: 0;
  padding: 0.25rem;
}

.dismiss svg {
  fill: var(--c-light-black);
  height: 1.25rem;
  width: 1.25rem;
}

/* Animation classes */

.dialog.animation-open {
  animation: dialog-open var(--animation-duration) ease forwards;
}

.dialog {
  animation: dialog-close var(--animation-duration) ease forwards;
}

.dialog.animation-open::backdrop {
  animation: backdrop-fade-in var(--animation-duration) ease forwards;
}

.dialog::backdrop {
  animation: backdrop-fade-out var(--animation-duration) ease forwards;
}

/* Animation keyframes */

@keyframes dialog-open {
  from {
    opacity: 0;
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes dialog-close {
  from {
    opacity: 1;
    transform: translateY(0%);
  }

  to {
    opacity: 0;
    transform: translateY(10%);
  }
}

@keyframes backdrop-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes backdrop-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>
