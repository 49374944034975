<template>
  <Page
    :header="$t('general.loading')"
  >
    <template
      v-if="includeToolbar"
      #toolbar
    >
      <div :class="$style.toolbar"></div>
    </template>
    <template #default>
      <Icon
        :class="$style.icon"
        :aria-label="$t('general.loading')"
        name="spinner"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Page from 'app/components/Page.vue';

export default defineComponent({
  name: 'LoadingPage',
  components: {
    Page
  },
  props: {
    includeToolbar: {
      type: Boolean,
      default: true
    }
  }
});
</script>

<style lang="less" module>
.toolbar {
  height: 40px;
}

.icon {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 2rem auto;
}
</style>
