<template>
  <button
    :id="ids.menu"
    ref="tagActions"
    type="button"
    :class="[$style.tagActionsMenu, 'dark']"
    @click="showTagActions = true"
  >
    {{ $t('tag.actions.header') }}
    <Icon name="overflow" />
  </button>
  <Popout
    v-if="showTagActions && tagActions"
    :class="$style.shield"
    :reference="tagActions"
    placement="bottom-end"
    :labelId="labelId"
    initialFocus="[data-focus_initial]"
    @close="onClose"
  >
    <template v-if="state === 'menu'">
      <div :class="$style.mainMenu">
        <button
          :class="$style.dismissIcon"
          :aria-label="$t('general.close')"
          data-focus_initial
          @click="onClose"
        >
          <Icon name="dismiss" />
        </button>
        <button
          type="button"
          :class="[$style.tagActionButton, 'focus-outline']"
          @click="state = 'renaming'"
        >
          {{ $t('tag.actions.rename.header') }}
        </button>
        <button
          type="button"
          :class="[$style.tagActionButton, 'focus-outline']"
          @click="state = 'deleting'"
        >
          {{ $t('tag.actions.delete.header') }}
        </button>
      </div>
    </template>

    <template v-else-if="state === 'renaming'">
      <form
        :class="$style.actionMenu"
        @submit.prevent="onRenameSubmit"
      >
        <button
          :class="$style.dismissIcon"
          :aria-label="$t('general.close')"
          @click="onClose"
        >
          <Icon name="dismiss" />
        </button>
        <h3
          :id="ids.rename"
          :class="$style.actionHeader"
        >
          {{ $t('tag.actions.rename.header') }}
        </h3>
        <input
          :id="ids.textInput"
          :value="tagRenameVal"
          type="text"
          name="tagName"
          :class="[$style.renameInput,'focus-outline']"
          autocapitalize="off"
          autocomplete="off"
          autocorrect="off"
          :maxlength="Tag.MAX_LENGTH"
          :placeholder="$t('tag.actions.rename.placeholder')"
          :aria-labelledby="ids.rename"
          required
          data-focus_initial
          @keydown.enter.prevent="onRenameSubmit"
          @input="updateRenameValue"
        />
        <FormError
          v-if="isInvalidRename"
          :class="$style.renameWarning"
          :contents="$t('tag.actions.rename.invalid')"
        />
        <div :class="$style.actionActions">
          <button
            type="submit"
            :class="[$style.tagActionButton, 'focus-outline', { 'disabled': isInvalidRename }]"
            :disabled="isInvalidRename"
          >
            {{ $t('tag.actions.rename.confirm') }}
          </button>
          <button
            type="button"
            :class="[$style.tagActionButton, 'focus-outline']"
            @click="onClose"
          >
            {{ $t('tag.actions.rename.cancel') }}
          </button>
        </div>
      </form>
    </template>

    <template v-else-if="state === 'deleting'">
      <div
        :class="$style.actionMenu"
      >
        <button
          :class="$style.dismissIcon"
          :aria-label="$t('general.close')"
          data-focus_initial
          @click="onClose"
        >
          <Icon name="dismiss" />
        </button>
        <h3
          :id="ids.delete"
          :class="$style.actionHeader"
        >
          {{ $t('tag.actions.delete.header') }}
        </h3>
        <div>
          {{ $t('tag.actions.delete.warning') }}
        </div>
        <div :class="$style.actionActions">
          <button
            type="button"
            :class="[$style.tagActionButton, 'focus-outline']"
            @click="onDeleteClick"
          >
            {{ $t('tag.actions.delete.confirm') }}
          </button>
          <button
            type="button"
            :class="[$style.tagActionButton, 'focus-outline']"
            @click="onClose"
          >
            {{ $t('tag.actions.delete.cancel') }}
          </button>
        </div>
      </div>
    </template>
  </Popout>
</template>

<script lang='ts'>
import FormError from 'app/components/FormError.vue';
import Popout from 'app/components/Popout.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { useI18n } from 'app/functions/use-i18n';
import { Tag } from 'app/models/tag';
import { Tags } from 'app/models/tags';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'TagActions',
  components: {
    FormError,
    Popout
  },
  props: {
    tag: {
      type: Tag,
      required: true
    }
  },
  setup: (props) => {
    const { dispatch } = useAppEvents();
    const { t } = useI18n();
    const myTagsLink = { name: RouteName.Tags };
    const showTagActions = ref<boolean>(false);
    const state = ref<'menu' | 'renaming' | 'deleting'>('menu');
    const tagActions = ref<HTMLElement | null>(null);
    const tagInput = ref<HTMLInputElement | null>(null);
    const tagRenameVal = ref(props.tag.name);
    const isInvalidRename = ref(false);
    const idBase = `tag-${props.tag.slug}`;
    const ids = {
      menu: `${idBase}-actions`,
      rename: `${idBase}-rename`,
      textInput: `${idBase}-input`,
      delete: `${idBase}-delete`
    };

    const labelId = computed(() => state.value === 'menu'
      ? ids.menu
      : state.value === 'renaming'
      ? ids.rename
      : ids.delete);

    // Quirk for suggestion keyboards where v-model only updates at the end of the word
    // Using :value/@input pair to work around that
    const updateRenameValue = (renameEvent: Event) => {
      if (renameEvent.target) {
        const target = renameEvent.target as HTMLInputElement;
        tagRenameVal.value = target.value;
      } else {
        tagRenameVal.value = '';
      }

      const trimmedVal = tagRenameVal.value.trim();
      if (!Tags.SAME_NAME(trimmedVal, props.tag.name) && !Tag.isValid(trimmedVal)) {
        isInvalidRename.value = true;
      } else {
        isInvalidRename.value = false;
      }
    };

    const router = useRouter();
    const onRenameSubmit = () => {

      if (isInvalidRename.value) {
        return;
      }

      const trimmedVal = tagRenameVal.value.trim();

      if (props.tag.name === trimmedVal) {
        onClose();

        return;
      }

      props.tag.rename(trimmedVal);
      router.replace({ name: RouteName.TagDetails, params: {
        tagSlug: props.tag.slug
      }});
      onClose();
    };

    const onDeleteClick = () => {
      props.tag.delete();
      dispatch('toast', { type: 'success', message: t('tag.actions.delete.success') });
      router.push({ name: RouteName.Tags });
    };

    const onClose = () => {
      showTagActions.value = false;
      tagRenameVal.value = props.tag.name;
      isInvalidRename.value = false;
      state.value = 'menu';
    };

    return {
      state,
      tagRenameVal,
      isInvalidRename,
      showTagActions,
      myTagsLink,
      labelId,
      ids,
      Tag,

      tagActions,
      tagInput,

      onDeleteClick,
      onClose,
      onRenameSubmit,
      updateRenameValue
    };
  }
});
</script>

<style module>

  .tag-actions-menu {
    display: flex;
    padding: 0.25rem;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }

  .tag-actions-menu svg {
    fill: var(--c-white);
    width: 1.5rem;
    height: 1.5rem;
  }

  .main-menu {
    display: grid;
    grid-template-rows: auto auto auto;
    align-items: center;
    padding: 1rem;
    row-gap: 0.75rem;
  }

  .dismiss-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    padding: 0.25rem;
  }

  .dismiss-icon svg {
    width: 1rem;
    height: 1rem;
  }

  .tag-action-button {
    color: inherit;
    display: block;
    padding: 0.25rem;
    text-align: left;
    justify-self: flex-start;
    text-decoration: underline;
  }

  .shield {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0.2, 0.2, 0.2, 0.2);
    transition: opacity 0.5s;
  }

  .action-menu {
    padding: 1.25rem;
    max-width: 12.5rem;
    display: grid;
    row-gap: 1rem;
    grid-template-rows: auto auto auto auto;
  }

  .action-header {
    font-weight: var(--fw-medium-bold);
  }

  .action-actions {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: min-content min-content;
  }

  .rename-input {
    color: var(--c-medium-black);
    background: var(--c-light-gray);
    border: 1px solid var(--c-light-gray);
    padding: 0.625rem 0.5rem;
    font-size: 1rem;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
  }

  .rename-warning {
    margin-bottom: -0.5rem;
  }
</style>
