<template>
  <section :class="$style.container">
    <button
      ref="tagIcon"
      :aria-label="$t('tags.header')"
      aria-haspopup="dialog"
      :class="$style.indicatorButton"
      @click="showTagWidget = true"
    >
      <Icon
        name="nav-tags"
        :class="$style.cardIcon"
      />
      <div :class="$style.badge">
        {{ tagCount }}
      </div>
    </button>
    <Popout
      v-if="showTagWidget && tagIcon"
      labelId="TagWidget"
      :reference="tagIcon"
      @close="showTagWidget = false"
    >
      <div :class="$style.popout">
        <Icon
          name="nav-tags"
          :class="$style.headerIcon"
        />
        <h3
          :class="$style.headerLabel"
        >
          {{ $t('tags.header') }}
        </h3>
        <button
          :class="$style.dismissIcon"
          :aria-label="$t('general.close')"
          @click="showTagWidget = false"
        >
          <Icon name="dismiss" />
        </button>
        <div :class="$style.widget">
          <TagWidget
            :title="title"
            :restrictHeight="true"
          />
        </div>
      </div>
    </Popout>
  </section>
</template>

<script lang="ts">
import Popout from 'app/components/Popout.vue';
import TagWidget from 'app/components/TagWidget.vue';
import { usePatron } from 'app/functions/use-patron';
import { Tag } from 'app/models/tag';
import { TitleRecord } from 'app/models/title';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'TagIndicator',
  components: {
    Popout,
    TagWidget
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    }
  },
  setup: (props) => {
    const tagIcon = ref<HTMLButtonElement | null>(null);

    const showTagWidget = ref(false);

    const { tags } = usePatron();
    const tagCount = computed(() => {
      const forTitle = tags.value
        .filter(Tag.FILTER_FUNCTIONS.nonSystemTags)
        .filter((tag) => tag.isAppliedToTitle(props.title.slug));

      return forTitle.length > 99 ? '99+' : forTitle.length.toString();
    });

    return {
      showTagWidget,
      tagCount,
      tagIcon
    };
  }
});
</script>

<style module>

.container {
  display: flex;
  align-items: center;
}

.indicator-button {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.indicator-button > * {
  margin: 0;
}

.card-icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--c-light-black);
  stroke: none;
}

.badge {
  composes: badge from global;
  margin-left: 0;
}

.popout {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "header-icon  header-label  dismiss-icon"
    ".            widget        widget";
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 1rem;
  width: 17rem;
}

.header-label {
  grid-area: header-label;
}

.header-icon {
  grid-area: header-icon;
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--c-secondary-gray);
  stroke: none;
}

.dismiss-icon {
  grid-area: dismiss-icon;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
}

.dismiss-icon svg {
  width: 1rem;
  height: 1rem;
}

.widget {
  grid-area: widget;
}
</style>
