<template>
  <div
    :class="[
      $style.emptyState,
      isCompact ? $style.compact : '',
      isDarkMode ? $style.darkMode : ''
    ]"
  >
    <Icon
      :name="iconName"
      :class="$style[iconClass]"
    />
    <header
      aria-live="polite"
      :class="$style.header"
    >
      {{ header }}
    </header>
    <div :class="$style.content">
      {{ content }}
    </div>
    <a
      v-if="link"
      :class="[$style.link, 'focus-outline']"
      target="_blank"
      :href="link"
      v-html="linkLabel"
    >
    </a>
  </div>
</template>

<script lang="ts">
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { computed, defineComponent } from 'vue';

/**
 * An empty state for when there is nothing else to show
 * @displayName EmptyState
 */
export default defineComponent({
  name: 'EmptyState',
  props: {
    /**
     * The text to display in the header of the empty state
     */
    header: {
      type: String,
      required: true
    },
    /**
     * The main content to display in the empty state
     */
    content: {
      type: String,
      required: true
    },
    /**
     * When true, compact styles and icons are used. Good for tight spaces.
     */
    isCompact: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * When true, icons and styles will be based on the dark theme
     */
    isDarkMode: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * URL to more information.
     * Note: If "link" is not defined, the help link label will not be rendered.
     */
    link: {
      type: String,
      required: false,
      default: undefined
    },
    /**
     * The label to use for the help link in the empty state
     * Note: If "link" is not defined, this label will not be rendered.
     */
    linkLabel: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const { windowWidth } = useWindowSize();
    const isMobile = computed(() => windowWidth.value <= Breakpoint.Tablet);
    const lightnessMod = computed(() => props.isDarkMode ? 'dark' : 'light');
    const styleMod = computed(() => props.isCompact
      ? 'compact'
      : isMobile.value
        ? 'short'
        : 'wide'
    );
    const iconName = computed(() => `es-generic-${lightnessMod.value}-${styleMod.value}`);
    const iconClass = computed(() => props.isCompact
      ? 'logoImageCompact'
      : isMobile.value
        ? 'logoImageShort'
        : 'logoImageWide'
    );

    return {
      iconName,
      iconClass
    };
  }
});
</script>

<style lang="less" module>
@import '../../app/views/core/base.less';

.empty-state {
  display: grid;
  justify-content: center;
  grid-template-columns: 32rem;
  grid-auto-rows: max-content;

  font-size: @fs-med;
  text-align: center;
  background-color: var(--c-white);
  padding: 5rem 2rem 2rem;
  border-radius: 0 0 .5rem .5rem;

  flex: 1;

  @media screen and (max-width: @px-vp-tablet) {
    grid-template-columns: inherit;
  }

}

.header {
  font-size: @fs-loud;
  color: @c-dark-black;
  font-weight: @fw-bold;
  line-height: @lh-head;
  padding-bottom: 1rem;
}

.content {
  color: @c-medium-black;
  line-height: @lh-body;
  padding-bottom: 1.6rem;
}

.link {
  .pop(@c-primary-blue);
  color: @c-primary-blue;
  display: inline;
  line-height: @lh-body;
}

// Overrides

.compact {
  margin: 0;
  padding: .5rem;
  max-width: 38rem;
  border-radius: 0;
  display: inline-block;
}

.dark-mode {

  background-color: inherit;

  .header {
    color: @c-white;
  }
  .content {
    color: @c-dark-gray;
  }
  .link {
    color: @c-darkest-gray;
    .pop(@c-darkest-gray);
  }
}

// Logo Classes

.logo-image-wide {
  display: inline;
  margin: 0 auto;
  width: 400px;
  height: 100px;
}

.logo-image-short {
  display: inline;
  margin: 0 auto;
  width: 325px;
  height: 100px;
}

.logo-image-compact {
  margin: 0 initial;
  width: 190px;
  height: 50px;
}
</style>
