<template>
  <div
    :class="{
      [$style.card]: true,
      [$style.unreviewed]: job.status === 'FINISHED' && !job.reviewed
    }"
  >
    <div
      v-if="job.status === 'ERROR'"
      :class="$style.error"
    >
      <header>
        <p>
          {{ releaseLabel }}
        </p>
      </header>
      <div :class="$style.additional">
        <Icon
          name="warning"
          :class="$style.errorIcon"
        />
        <p>
          {{ $t('annotationCopying.manageCopying.card.error') }}
        </p>
      </div>
      <div :class="$style.actions">
        <FormSubmitButton
          :label="$t('annotationCopying.banner.actions.retry')"
          size="small"
          @click="$emit('update', 'retry')"
        />
        <FormSubmitButton
          :label="$t('annotationCopying.banner.actions.clear')"
          size="small"
          :class="$style.clear"
          @click="$emit('update', 'clear')"
        />
      </div>
    </div>

    <div
      v-else-if="job.status === 'IN_PROGRESS' || job.status === 'QUEUED'"
      :class="$style.progress"
    >
      <header>
        <p>
          {{ releaseLabel }}
        </p>
        <Icon
          name="spinner"
          :class="$style.progressIcon"
        />
      </header>
      <p :class="$style.additional">
        {{ $t('annotationCopying.manageCopying.card.inProgress') }}
      </p>
    </div>

    <div
      v-else
      :class="$style.finished"
    >
      <p
        v-if="!job.reviewed"
        :class="$style.unreviewedTag"
      >
        {{ $t('annotationCopying.manageCopying.card.complete.unreviewed') }}
      </p>
      <header>
        <p>
          {{ releaseLabel }}
        </p>
      </header>
      <dl :class="$style.additional">
        <dt>{{ $t('annotationCopying.manageCopying.card.complete.copied') }}</dt>
        <dd v-html="numCopied"></dd>
        <dt>{{ $t('annotationCopying.manageCopying.card.complete.finished') }}</dt>
        <dd>
          <RelativeDate
            :timestamp="Date.parse(job.created_at)"
          />
        </dd>
      </dl>
      <div :class="$style.actions">
        <FormSubmitButton
          :label="$t('annotationCopying.banner.actions.review')"
          size="small"
          @click="$emit('update', 'review')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PostishCopyJobResponse } from 'app/base/postish';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import { getReleaseLabel } from 'app/functions/use-annotation-copying';
import { useI18n } from 'app/functions/use-i18n';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'CopyJobCard',
  components: {
    FormSubmitButton,
    RelativeDate
  },
  props: {
    job: {
      type: Object as PropType<PostishCopyJobResponse>,
      required: true
    }
  },
  emits: [
    'update'
  ],
  setup: (props) => {
    const { t } = useI18n();

    const releaseLabel = getReleaseLabel(props.job.source_title, props.job.target_title);

    const numCopied = computed(() => {
      return props.job.status === 'FINISHED'
        ? t('annotationCopying.manageCopying.card.complete.numCopied', {
          SUCCESSFUL: props.job.results.successes.length,
          TOTAL: props.job.results.successes.length + props.job.results.failures.length
        })
        : undefined;
    });

    return {
      numCopied,
      releaseLabel
    };
  }
});
</script>

<style module>
.card {
  padding: 1rem;
  border: 1px solid var(--c-dark-gray);
  border-radius: var(--form-border-radius);
  color: var(--c-dark-black);
}

.unreviewed {
  background-color: var(--c-light-gray);
  border-color: var(--c-darkest-gray);
}

.error header {
  font-weight: var(--fw-bold);
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--c-dark-gray);
}

.error .additional {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  padding-top: 1rem;
  color: var(--c-error-orange);
}

.error-icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--c-error-orange);
}

.progress header {
  display: grid;
  grid-template-columns: 1fr auto;
  font-weight: var(--fw-bold);
  padding-bottom: 1rem;
}

.progress-icon {
  height: 2rem;
  width: 2rem;
}

.progress .additional {
  color: var(--c-light-black);
  font-size: var(--fs-metadata);
}

.unreviewed-tag {
  text-transform: uppercase;
  background-color: var(--c-dark-black);
  color: var(--c-dark-gray);
  padding: 2px 5px;
  width: max-content;
  margin-bottom: 1rem;
  border-radius: var(--form-border-radius);
  font-size: var(--fs-small);
  font-weight: var(--fw-medium);
}

.finished header {
  grid-template-columns: 1fr auto;
  font-weight: var(--fw-bold);
}

.finished .additional {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);
  grid-gap: 0.25rem 1rem;
  font-size: var(--fs-metadata);
  padding: 1rem 0;
  border-bottom: 1px solid var(--c-dark-gray);
}

.finished .additional dt {
  color: var(--c-light-black);
  text-transform: uppercase;
}

.actions {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  column-gap: 1rem;
  padding-top: 1rem;
}

.actions .clear {
  background-color: var(--c-white);
  color: var(--c-dark-black);
  border: 1px solid var(--c-light-black);
}
</style>
