<template>
  <article :class="$style.card">
    <header
      :class="$style.header"
      :style="[
        `--background-color: rgb(${provider.backgroundColor.join(',')})`,
        `--font-color: rgb(${provider.fontColor.join(',')})`
      ]"
    >
      <h2>
        {{ provider.name }}
      </h2>
      <img
        :src="provider.icon"
        :class="$style.icon"
        role="presentation"
      />
    </header>
    <section :class="$style.content">
      {{ provider.description }}
    </section>
    <footer :class="$style.footer">
      <button
        ref="accessButton"
        :class="{
          [$style.access]: true,
          [$style.disabled]: provider.unavailable
        }"
        :aria-disabled="provider.unavailable"
        aria-haspopup="dialog"
        @click="provider.unavailable ? undefined : $emit('access')"
      >
        {{ $t('ntc.access') }}
      </button>
      <p
        v-if="provider.unavailable"
        :class="$style.unavailableMessage"
      >
        {{ $t('ntc.unavailable') }}
      </p>

      <Popout
        v-if="error"
        :reference="accessButton"
        labelId="ntcError"
        descriptionId="ntcErrorDesc"
        @close="$emit('clearError')"
      >
        <div :class="$style.errorDialog">
          <h3 id="ntcError">
            {{ $t('ntc.error.header') }}
          </h3>
          <p id="ntcErrorDesc">
            {{ $t('ntc.error.message') }}
          </p>
          <button
            :aria-label="$t('general.close')"
            class="dark popout-dismiss"
            @click="$emit('clearError')"
          >
            <Icon name="dismiss" />
          </button>
        </div>
      </Popout>

      <dl
        v-if="provider.loan && !provider.unavailable"
        :class="$style.time"
      >
        <dt class="visually-hidden">
          {{ $t('ntc.expireTime') }}
        </dt>
        <dd>
          <Icon name="expire-clock" />
          <RelativeDate :timestamp="provider.loan.expireTime" />
        </dd>
      </dl>
    </footer>
  </article>
</template>

<script lang='ts'>
import Popout from 'app/components/Popout.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import { NtcProvider } from 'app/views/Ntc.vue';
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'NtcTitleCard',
  components: {
    Popout,
    RelativeDate
  },
  props: {
    provider: {
      type: Object as PropType<NtcProvider>,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'access',
    'clearError'
  ],
  setup: (props) => {
    const accessButton = ref<HTMLElement | null>(null);

    return {
      accessButton
    };
  }
});
</script>

<style module>
.card {
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  border-radius: var(--form-border-radius);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  background-color: var(--background-color);
  color: var(--font-color);
  border-radius: var(--form-border-radius) var(--form-border-radius) 0 0;
  display: flex;
  font-size: var(--fs-medium);
  padding: 1rem;
  align-items: center;
  font-weight: var(--fw-medium);
  gap: 10px;
}

.icon {
  height: 50px;
  width: 50px;
  margin-left: auto;
  border-radius: var(--form-border-radius);
}

.content {
  margin: 1rem;
  --line-clamp: 5;
  composes: line-clamp from global;
}

.footer {
  display: flex;
  padding: 1rem;
  align-items: center;
  margin-top: auto;
}

.access {
  border-radius: var(--form-border-radius);
  color: var(--c-primary-blue);
  font-weight: var(--fw-bold);
  font-size: var(--fs-body);

  width: max-content;

  min-width: calc(var(--rem-cover-width) - 2rem);
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  padding: .5rem 1rem;
  color: var(--c-primary-blue);
}

.access.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.unavailable-message {
  color: var(--c-primary-red);
  margin-left: 20px;
}

.error-dialog {
  padding: 0.5rem;
  max-width: 20rem;
}

.error-dialog h3 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.time {
  color: var(--c-light-black); /* insufficient color contrast */
  margin-left: auto;
}

.time svg {
  width: 1.25rem;
  height: 1.25rem;
  stroke: var(--c-light-black);
  vertical-align: sub;
  padding: 0 0.5rem;
}
</style>
