<template>
  <Surface>
    <RecentlyRead :class="$style.recentlyRead" />
    <div :class="$style.bottom">
      <HomeAnnotationList />
      <HomeTagList />
    </div>
  </Surface>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HomeAnnotationList from 'app/components/HomeAnnotationList.vue';
import HomeTagList from 'app/components/HomeTagList.vue';
import RecentlyRead from 'app/components/RecentlyRead.vue';
import Surface from 'app/components/Surface.vue';

export default defineComponent({
  name: 'Home',
  components: {
    HomeAnnotationList,
    HomeTagList,
    RecentlyRead,
    Surface
  }
});
</script>

<style lang='less' module>
  @import '../../app/views/core/base.less';

  .recently-read {
    grid-row: 1;
    grid-column: 1 / span 2;
  }

  .bottom {
    display: grid;
    grid-template-columns: auto 18rem;
    grid-column-gap: 4.5rem;
    padding: 2rem;
    background-color: var(--c-white);
    border-radius: 0 0 .5rem .5rem;
    flex: 1;

    @media screen and (max-width: @px-vp-tablet) {
      grid-template-columns: minmax(0, 1fr);
      grid-row-gap: 4.5rem;
    }

    @media screen and (max-width: @px-vp-narrow) {
      padding: 2rem 1rem;
    }

  }
</style>
