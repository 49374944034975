<template>
  <p
    v-if="submitting"
    :class="$style.loading"
  >
    <Icon
      name="spinner"
      :aria-label="$t('login.form.submitting')"
    />
  </p>
  <p
    v-else-if="auth.state === 'success'"
    :class="$style.loading"
  >
    <Icon
      name="spinner"
      :aria-label="$t('login.form.complete')"
    />
  </p>
  <div v-else>
    <form
      :class="$style.form"
      @submit.prevent="submit(formData)"
    >
      <FormError
        v-if="errorMessage"
        :class="$style.error"
        :contents="errorMessage"
      />

      <FormSelect
        v-if="dropdownForms.length > 1 && !emailSelected"
        v-model="selected"
        :options="dropdownForms"
        optionLabel="displayName"
        :optionKey="(f) => f.ilsName + f.displayName"

        :prompt="$t('login.form.prompt')"
      />

      <template v-if="selected">
        <LoginFormLocal
          v-if="selected.type === 'Local' && selected.local"
          :key="selected.sortName"
          :usernameConfig="selected.local.username"
          :passwordConfig="selected.local.password"
          :loginHintUrl="selected.loginHintUrl"
          :username="username"
          :password="password"
          @username="v => username = v"
          @password="v => password = v"
        />

        <LoginFormExternal
          v-else-if="selected.type === 'External'"
        />

        <EmailRegistration
          v-else-if="emailSelected"
          :showCode="auth.state === 'code'"
          :username="username"
          :password="password"
          @username="v => username = v"
          @password="v => password = v"
        />
      </template>


      <FormCaptcha
        v-if="captchaRequired"
        :siteKey="page.captcha.key"
      />
    </form>


    <section
      v-if="emailSelected"
      :class="$style.additional"
    >
      <button @click="clearSelection">
        {{ $t('login.email.cancel') }}
      </button>
    </section>

    <section
      v-else-if="guestForm || emailForm"
      :class="$style.additional"
    >
      <button
        v-if="guestForm"
        type="submit"
        @click="submit(guestForm)"
      >
        {{ $t('login.form.signInAsGuest') }}
      </button>

      <button
        v-if="emailForm"
        @click="() => selected = emailForm"
      >
        {{ $t('login.form.signInWithEmail') }}
      </button>
    </section>
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent, ref, watch } from 'vue';
import FormCaptcha from 'app/components/FormCaptcha.vue';
import LoginFormExternal from 'app/components/LoginFormExternal.vue';
import LoginFormLocal from 'app/components/LoginFormLocal.vue';
import FormSelect from 'app/components/FormSelect.vue';
import type { AuthLoginPage, LoginForm, LoginFormSubmission } from 'app/base/interfaces';
import { AuthenticationState } from 'app/functions/use-authentication';
import { AuthError } from 'app/base/auth-definitions';
import FormError from 'app/components/FormError.vue';
import EmailRegistration from 'app/components/EmailRegistration.vue';

export default defineComponent({
  name: 'LoginForm',
  components: {
    FormCaptcha,
    LoginFormExternal,
    LoginFormLocal,
    FormSelect,
    FormError,
    EmailRegistration
  },
  props: {
    page: {
      type: Object as () => AuthLoginPage,
      required: true
    },
    auth: {
      type: Object as () => AuthenticationState,
      required: true
    }
  },
  emits: [
    'cancel',
    'submit'
  ],
  setup: (props, ctx) => {
    const dropdownForms = computed(() =>
      props.page.forms
        .slice()
        .filter((f) => f.type === 'Local' || f.type === 'External')
        .sort((a, b) => a.sortName.localeCompare(b.sortName))
    );

    const guestForm = computed(() => {
      if (props.page.guestSession) {
        return {
          type: 'Guest' as const,
          ilsName: props.page.guestSession.ilsName
        };
      }

      return undefined;
    });

    const emailForm = computed(() =>
      props.page.forms.find((f) => f.type === 'EmailRegistration')
    );

    const selected = ref<LoginForm | null>(null);

    const clearSelection = () => {
      selected.value = dropdownForms.value.length === 1
        ? dropdownForms.value[0]
        : null;

      ctx.emit('cancel');
    };

    clearSelection();

    const emailSelected = computed(() => selected.value?.type === 'EmailRegistration');

    const formData = ref<LoginFormSubmission | null>(null);
    watch(selected, (to, from) => {
      if (!to) {
        formData.value = null;

        return;
      }

      if (to.ilsName !== formData.value?.ilsName) {
        formData.value = {
          type: to.type as LoginFormSubmission['type'],
          ilsName: to.ilsName,
          username: null,
          password: null
        };
      }
    }, { immediate: true });
    const username = computed({
      get: () => formData.value?.username || '',
      set: (v) => formData.value!.username = v
    });
    const password = computed({
      get: () => formData.value?.password || '',
      set: (v) => formData.value!.password = v
    });

    const captchaRequired = computed(() => {
      return props.auth.state === 'error'
        && props.auth.code === AuthError.CaptchaRequired;
    });

    const submit = (data: LoginFormSubmission | null | undefined) => {
      if (!data) { return; }

      try {
        if (captchaRequired.value && window.grecaptcha) {
          data.captcha = window.grecaptcha.getResponse();
        }
      } catch (err) {
        console.error('[CAPTCHA] Could not get captcha response', err);
      }

      ctx.emit('submit', { ...data });
    };

    const submitting = computed(() => {
      return props.auth.state === 'submitting';
    });

    const errorMessage = computed(() => {
      if (props.auth.state === 'error') {
        return props.auth.message;
      }

      if (props.auth.state === 'code' && props.auth.error) {
        return props.auth.error.message;
      }

      return undefined;
    });

    return {
      captchaRequired,
      clearSelection,
      dropdownForms,
      errorMessage,
      formData,
      emailForm,
      emailSelected,
      guestForm,
      selected,
      submit,
      submitting,
      username,
      password
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.form > * + * {
  margin-top: 1.5rem;
}

.additional {
  margin-top: 1.5rem;
  border-top: 1px solid @c-dark-gray;

  a, button {
    display: block;
    box-sizing: border-box;
    width: 100%;
    text-transform: uppercase;
    border: 1px solid @c-darkest-gray;
    border-radius: @px-border-radius;
    text-align: center;
    font-weight: @fw-bold;
    margin-top: 1.5rem;
    padding: 1rem;
    .focus-outline;
  }
}

.loading {
  width: 100%;
  height: 10rem;
  margin: auto;
  position: relative;

  svg {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.error {
  margin: auto;
}
</style>
