<template>
  <StatusBanner
    :status="mappedStatus"
    :label="statusLabel"
    :sublabel="releaseLabel"
  >
    <template #actions>
      <div
        v-if="mappedStatus === 'complete'"
        :class="$style.actions"
      >
        <FormSubmitButton
          :label="$t('annotationCopying.banner.actions.review')"
          size="small"
          :class="$style.submit"
          @click="$emit('update', 'review')"
        />
        <FormSubmitButton
          :label="$t('annotationCopying.banner.actions.dismiss')"
          size="small"
          :class="$style.dismiss"
          @click="$emit('update', 'dismiss')"
        />
      </div>

      <div
        v-if="mappedStatus === 'error'"
        :class="$style.actions"
      >
        <FormSubmitButton
          :label="$t('annotationCopying.banner.actions.retry')"
          size="small"
          :class="$style.submit"
          @click="$emit('update', 'retry')"
        />
        <FormSubmitButton
          :label="$t('annotationCopying.banner.actions.clear')"
          size="small"
          :class="$style.dismiss"
          @click="$emit('update', 'clear')"
        />
      </div>
    </template>
  </StatusBanner>
</template>

<script lang="ts">
import { PostishCopyStatus, PostishCopyTitleSnapshot } from 'app/base/postish';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import StatusBanner, { Status } from 'app/components/StatusBanner.vue';
import { getReleaseLabel } from 'app/functions/use-annotation-copying';
import { useI18n } from 'app/functions/use-i18n';
import { PropType, computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'CopyStatusBanner',
  components: {
    FormSubmitButton,
    StatusBanner
  },
  props: {
    status: {
      type: String as PropType<PostishCopyStatus>,
      required: true
    },
    source: {
      type: Object as PropType<PostishCopyTitleSnapshot>,
      required: true
    },
    target: {
      type: Object as PropType<PostishCopyTitleSnapshot>,
      required: true
    }
  },
  emits: [
    'update'
  ],
  setup: (props) => {
    const { t } = useI18n();

    const releaseLabel = getReleaseLabel(props.source, props.target);

    const mappedStatus = computed(() => {
      return props.status === 'FINISHED'
        ? 'complete'
        : props.status === 'ERROR'
          ? 'error'
          : 'inProgress' as Status;
    });

    const statusLabel = computed(() => {
      return mappedStatus.value === 'inProgress'
        ? t('annotationCopying.banner.status.inProgress')
        : mappedStatus.value === 'complete'
          ? t('annotationCopying.banner.status.complete')
          : t('annotationCopying.banner.status.error');
    });

    return {
      mappedStatus,
      statusLabel,
      releaseLabel
    };
  }
});
</script>

<style module>
.actions {
  margin: 0.75rem 1rem;
  display: flex;
  column-gap: 1rem;
}

.submit {
  background-color: var(--c-white);
  color: var(--c-dark-black);
  border: 1px solid var(--c-white);
  height: 40px;
  line-height: 0;
}

.dismiss {
  background-color: var(--c-dark-black);
  border: 1px solid var(--c-light-black);
  height: 40px;
  line-height: 0;
}
</style>
