<template>
  <DetailsPageContent
    :title="title.title"
    :subtitle="title.subtitle"
    :authors="title.creators.Author"
    :metadata="metadata"
  >
    <template #context>
      <TitleDetailsContextMenuButton
        :title="title"
        :class="$style.contextMenu"
      />
    </template>

    <template #cover>
      <Cover
        :item="title"
        :class="$style.cover"
      />
    </template>

    <template #pillar>
      <TitleActionButton
        :title="title"
        :class="$style.action"
        display="button"
      />

      <TitleDetailsAnnotationsLink
        v-if="annotationCount > 0 && title.mediaType !== 'audiobook'"
        :titleSlug="title.slug"
        :annotationCount="annotationCount"
      />
      <div :class="$style.interactContainer">
        <CopyLink
          :class="[$style.interactButton, $style.copyLink]"
        />
        <button
          :class="[$style.interactButton, $style.subscribe]"
          :aria-label="subscribeTextLong"
          @click="toggleSubscription()"
        >
          <div>
            <Icon
              :class="$style.bell"
              name="alert-bell"
            />
          </div>
          <span>{{ subscribeTextShort }}</span>
        </button>
      </div>

      <div>
        <h2 :class="$style.headerLabel">
          <Icon
            name="nav-tags"
            :class="$style.headerIcon"
          />
          {{ $t('tags.header') }}
        </h2>
        <TagWidget :title="title" />
      </div>

      <TitleDetailsSeriesLink
        v-if="title.seriesId"
        :series="series"
      />
    </template>

    <template #body>
      <section>
        <TitleDetailsAccordion
          :title="title"
          :series="series"
        />
      </section>

      <section
        v-if="title.isLexisPublished
          && title.hasODRFormat
          && title.mediaType === 'book'
        "
      >
        <h2 :class="$style.sectionHead">
          {{ $t('searchThisTitle.header.title') }}
        </h2>

        <SearchThisTitle
          :item="title"
          :params="searchThisTitle"
        />
      </section>
    </template>

    <template #footer>
      <section
        v-if="recommended.length"
        :class="$style.recommended"
      >
        <h2 :class="$style.sectionHead">
          {{ $t('title.recommended') }}
        </h2>

        <ul :class="$style.cardGrid">
          <ol
            v-for="item in recommended"
            :key="item.slug"
          >
            <TitleCard :title="item" />
          </ol>
        </ul>
      </section>
    </template>
  </DetailsPageContent>
</template>

<script lang='ts'>
import { SearchThisTitleQuery } from 'app/base/hudson';
import CopyLink from 'app/components/CopyLink.vue';
import Cover from 'app/components/Cover.vue';
import DetailsPageContent, { MetadataItem } from 'app/components/DetailsPageContent.vue';
import SearchThisTitle from 'app/components/SearchThisTitle.vue';
import TagWidget from 'app/components/TagWidget.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import TitleDetailsAccordion from 'app/components/TitleDetailsAccordion.vue';
import TitleDetailsAnnotationsLink from 'app/components/TitleDetailsAnnotationsLink.vue';
import TitleDetailsSeriesLink from 'app/components/TitleDetailsSeriesLink.vue';
import TitleCard from 'app/components/cards/TitleCard.vue';
import TitleDetailsContextMenuButton from 'app/components/contextMenus/TitleDetailsContextMenuButton.vue';
import { useI18n } from 'app/functions/use-i18n';
import { useTitleSubscription } from 'app/functions/use-subscription-interaction';
import type { Series } from 'app/models/series';
import type { TitleRecord } from 'app/models/title';
import { title } from 'app/views/core/cards/title-card.module.less';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleDetailsContent',
  components: {
    CopyLink,
    TitleActionButton,
    TitleCard,
    TitleDetailsAccordion,
    TitleDetailsAnnotationsLink,
    TitleDetailsContextMenuButton,
    TitleDetailsSeriesLink,
    DetailsPageContent,
    Cover,
    SearchThisTitle,
    TagWidget
  },
  props: {
    title: {
      type: Object as () => TitleRecord,
      required: true
    },
    series: {
      type: Object as () => Series | null,
      default: null
    },
    annotationCount: {
      type: Number,
      default: 0
    },
    onLoan: {
      type: Boolean,
      default: false
    },
    recommended: {
      type: Array as () => TitleRecord[],
      default: () => []
    },
    searchThisTitle: {
      type: Object as () => SearchThisTitleQuery | null,
      default: null
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();

    const {
      subscribeTextShort,
      subscribeTextLong,
      toggleSubscription
    } = useTitleSubscription(props.title, 'title details page');

    const metadata = computed(() => {
      const meta = props.title.lexisMetadata;
      if (!meta) {
        return undefined;
      }

      const metadataList: MetadataItem[] = (['edition', 'release', 'releaseDate'] as const)
        .filter((m) => !!meta[m])
        .map((m) => {
          return {
            label: `title.${m}`,
            value: meta[m]!,
            show: true
          };
        });

      if (props.title.circ) {
        metadataList.push({
          label: 'title.copiesAvailable.label',
          value: t(
            'title.copiesAvailable.message',
            {
              available: props.title.circ.copiesAvailable,
              total: props.title.circ.copiesOwned
            }
          ) as string,
          show: !props.onLoan
            && !props.title.isSimultaneousUse
            && props.title.circ?.copiesOwned > 0
        });
      }

      return metadataList;
    });

    return {
      metadata,
      subscribeTextShort,
      subscribeTextLong,
      toggleSubscription
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.context-menu {
  margin-left: auto;
}

.cover {
  width: @rem-details-page-cover-width;
  height: @rem-details-page-cover-height;
  border-radius: .25rem .625rem .625rem .25rem;
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  box-shadow: 2px 3px 2px 1px rgba(var(--c-primary-blue-rgb), .15);
}

.copy-link {
  width: 100%;
}

.section-head {
  font-size: var(--fs-body);
  font-weight: var(--fw-medium-bold);
  margin-bottom: 0.75rem;
}


.recommended {
  padding-top: 2rem;
  border-top: 1px solid @c-dark-gray;
}

.interact-container {
  display: grid;
  width: calc(@rem-details-page-cover-width + 2px);
  margin: 0 auto;
  grid-template-areas: 'copy subscribe';
  grid-template-columns: auto auto;
  justify-content: space-between;
}
.interact-button {
  display: grid;
  width: fit-content;
  grid-template-areas: 'icon text';
  grid-template-columns: auto auto;
  align-items: center;
  gap: 0.25rem;
}

.interact-button div {
  grid-area: icon;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border: 1px solid rgba(41, 98, 255, 0.1);
  border-radius: 50%;
  background-color: #F4F7FF;
}

.interact-button svg {
  height: 1.5rem;
  width: 1.25rem;
  fill: var(--c-primary-blue);
}

.interact-button span {
  grid-area: text;
  color: var(--c-primary-blue);
  text-decoration: underline;
}

.copy-link {
  grid-area: copy;
}

.subscribe {
  grid-area: subscribe;
}

.bell {
  stroke: var(--c-primary-blue);
}
.header-label {
  display: flex;
  align-items: center;
  color: var(--c-light-black);
  font-weight: var(--fw-medium);
  padding-bottom: 0.5rem;
}

.header-icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: currentColor;
  margin-right: 0.5rem;
}


// ## TABLET LAYOUT

@media screen and (max-width: @px-vp-very-wide) {
  .cover,
  .action {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .action {
    margin-bottom: .25rem;
  }
}
</style>
