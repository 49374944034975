<template>
  <Surface>
    <Page
      :header="library.name"
      :useBackToTop="true"
      :class="$style.page"
    >
      <Icon
        v-if="loading"
        name="spinner"
        :class="$style.loading"
        :aria-label="$t('general.loading')"
      />
      <div v-else>
        <BrowseGroupCollection
          v-for="collection in showableCollections"
          :key="collection.id"
          :class="$style.group"
          :collectionId="collection.id"
          :name="collection.name"
          :description="collection.description"
          :searchType="collection.searchType"
          :definition="collection.definition"
        />

        <BrowseGroupSubjects
          :subjects="showableSubjects"
          :class="$style.group"
        />

        <section :class="$style.group">
          <router-link
            :class="$style.button"
            :to="fullCollectionNavigateLink"
          >
            {{ $t('browse.fullCollection') }}
          </router-link>
        </section>
      </div>
    </Page>
  </Surface>
</template>

<script lang='ts'>
import { defineComponent, ref, computed } from 'vue';
import BrowseGroupCollection from 'app/components/BrowseGroupCollection.vue';
import BrowseGroupSubjects from 'app/components/BrowseGroupSubjects.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import { APP } from 'app/base/app';
import { RouteName } from 'app/router/constants';
import { ThunderFacetItem, ThunderView } from 'app/base/thunder';
import { useLibrary } from 'app/functions/use-library';
import { SearchType, toSearchDefinition } from 'app/base/search';

type BrowseSearchType = 'collection' | 'subject';

type State<T extends BrowseSearchType> =
  {
    state: 'loading';
  }
  | {
    state: 'loaded';
    results: T extends 'collection' ? ThunderView : ThunderFacetItem[];
  }
  | {
    state: 'notfound';
  };

export default defineComponent({
  name: 'Browse',
  components: {
    BrowseGroupCollection,
    BrowseGroupSubjects,
    Page,
    Surface
  },
  setup: (props, ctx) => {
    const library = useLibrary();

    // Collection Definitions

    const collectionState = ref<State<'collection'>>({ state: 'loading' });

    const getCollections = async () => {
      try {
        const collections = await APP.services.thunder.getPages(APP.library.key(), true);

        if (!collections) {
          collectionState.value = {
            state: 'notfound'
          };

          return;
        }

        collectionState.value = {
          state: 'loaded',
          results: collections.items.find((item) => item.isDefault)!.views.find((view) => view.isDefault)!
        };
      } catch {
        collectionState.value = {
          state: 'notfound'
        };
      }
    };

    getCollections();

    const showableCollections = computed(() => {
      if (collectionState.value.state === 'loaded') {
        const collectionOrder = collectionState.value.results.collections.reduce((obj, collection) => {
          obj[collection.id] = collection.order;

          return obj;
        }, {} as Record<string, number>);

        return collectionState.value.results.collectionDefinitions.map((definition) => {
          return {
            id: definition.id,
            name: definition.name,
            description: definition.description,
            searchType: definition.itemType === 'Series' ? SearchType.Series : SearchType.Title,
            definition: toSearchDefinition(definition)
          };
        }).sort((a, b) => collectionOrder[a.id] - collectionOrder[b.id]);
      }

      return [];
    });

    const fullCollectionNavigateLink = {
      name: RouteName.Search
    };

    // Subjects

    const subjectState = ref<State<'subject'>>({ state: 'loading' });

    const getSubjects = async () => {
      try {
        const subjects = await APP.services.thunder.getSubjects(APP.library.key());

        if (!subjects) {
          subjectState.value = {
            state: 'notfound'
          };

          return;
        }

        subjectState.value = {
          state: 'loaded',
          results: subjects
        };
      } catch {
        subjectState.value = {
          state: 'notfound'
        };
      }
    };

    getSubjects();

    const showableSubjects = computed(() => {
      return subjectState.value.state === 'loaded'
        ? subjectState.value.results
        : [];
    });

    const loading = computed(() => {
      return collectionState.value.state === 'loading' || subjectState.value?.state === 'loading';
    });

    return {
      fullCollectionNavigateLink,
      library,
      loading,
      showableCollections,
      showableSubjects
    };
  }
});
</script>

<style module>

.page header:not(.group header) {
  padding: 4rem 2rem 2rem;
}

@media screen and (max-width: 499px /* @px-vp-narrow */) {
  .page header:not(.group header) {
    padding: 3rem 1rem 1rem;
  }
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 5rem auto;
}

.group:first-child {
  padding-top: 0px;
}

.group:not(:first-child) {
  border-top: 1px solid var(--c-dark-gray);
}

.button {
  margin: 50px auto 25px;
  display: table;
  padding: 10px 20px;
  color: var(--c-white);
  border-radius: var(--form-border-radius);
  background: var(--c-primary-red);
  font-weight: var(--fw-medium);
  text-transform: uppercase;
  composes: focus-outline from global;
}
</style>
