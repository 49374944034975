<template>
  <Surface>
    <Page
      v-if="state.status === 'loaded'"
      :header="$t('copyReview.header')"
    >
      <template #subheader>
        <h2 :class="$style.title">
          {{ state.title.title }}
        </h2>
        <p v-if="state.title.subtitle">
          {{ state.title.subtitle }}
        </p>
        <p :class="$style.release">
          {{ state.release }}
        </p>
      </template>

      <template #default>
        <div :class="$style.warning">
          <Icon
            :class="$style.warningIcon"
            name="warning"
          />
          <span
            :class="$style.warningText"
          >
            {{ $t('copyReview.warning') }}
          </span>
        </div>
        <template v-if="state.successes.length > 0 || state.failures.length > 0">
          <CopyReviewSection
            :class="$style.reviewSection"
            :message="$t('copyReview.successes.count', { COUNT: state.successes.length })"
            :description="$t('copyReview.successes.description')"
            :snapshots="state.successes"
            :hideNote="hideNote"
          >
            <template #icon>
              <Icon
                :class="$style.successIcon"
                name="dl-downloaded"
              />
            </template>
          </CopyReviewSection>
          <CopyReviewSection
            :class="$style.reviewSection"
            :message="$t('copyReview.failures.count', { COUNT: state.failures.length })"
            :description="$t('copyReview.failures.description')"
            :snapshots="state.failures"
            :hideNote="hideNote"
          >
            <template #icon>
              <Icon
                :class="$style.failureIcon"
                name="dismiss"
              />
            </template>
          </CopyReviewSection>
        </template>
        <EmptyState
          v-else
          :header="$t('copyReview.empty.header')"
          :content="$t('copyReview.empty.content')"
        />
      </template>
    </Page>

    <LoadingPage
      v-else-if="state.status === 'loading'"
      :includeToolbar="false"
    />

    <NotFound v-else />
  </Surface>
</template>

<script lang="ts">
import { PostishCopyAnnotationSnapshot, PostishCopyTitleSnapshot } from 'app/base/postish';
import CopyReviewSection from 'app/components/CopyReviewSection.vue';
import EmptyState from 'app/components/EmptyState.vue';
import LoadingPage from 'app/components/LoadingPage.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import { getReleaseLabel } from 'app/functions/use-annotation-copying';
import { useCopyAnnotationSnapshots } from 'app/functions/use-copy-annotation-snapshots';
import { useCopyJob } from 'app/functions/use-copy-job';
import { useLibrary } from 'app/functions/use-library';
import { useTitle } from 'app/functions/use-title';
import { TitleRecord } from 'app/models/title';
import NotFound from 'app/views/NotFound.vue';
import { computed, defineComponent } from 'vue';


type PageState = {
  status: 'loaded';
  title: TitleRecord;
  release: string;
  successes: PostishCopyAnnotationSnapshot[];
  failures: PostishCopyAnnotationSnapshot[];
} | {
  status: 'loading';
} | {
  status: 'error';
};

export default defineComponent({
  name: 'CopyReview',
  components: {
    CopyReviewSection,
    EmptyState,
    LoadingPage,
    NotFound,
    Page,
    Surface
  },
  props: {
    jobId: {
      type: String,
      required: true
    }
  },
  setup: (props) => {
    const library = useLibrary();
    const hideNote = computed(() => library.value?.disableNotes || false);

    const {
      copyJob,
      loading: copyJobLoading
    } = useCopyJob(props.jobId);

    const {
      copyAnnotationSnapshots,
      loading: copyAnnotationSnapshotsLoading
    } = useCopyAnnotationSnapshots(props.jobId);

    const targetTitleId = computed(() => copyJob.value?.target_title.title_id || null);
    const {
      title: targetTitle,
      loading: targetTitleLoading
    } = useTitle(targetTitleId);

    const successes = computed<PostishCopyAnnotationSnapshot[]>(() => {
      const snapshots = copyAnnotationSnapshots.value;
      if (!snapshots) { return []; }

      const job = copyJob.value;
      if (!job) { return []; }

      const successUuids = job.results.successes.map((s) => s.copied);

      const successSnapshots = snapshots.filter((snap) => successUuids.includes(snap.uuid)) as PostishCopyAnnotationSnapshot[];

      return successSnapshots.sort((a, b) => b.syncstamp - a.syncstamp);
    });

    const failures = computed<PostishCopyAnnotationSnapshot[]>(() => {
      const snapshots = copyAnnotationSnapshots.value;
      if (!snapshots) { return []; }

      const job = copyJob.value;
      if (!job) { return []; }

      const failureUuids = job.results.failures;

      const failureSnapshots = snapshots.filter((snap) => failureUuids.includes(snap.uuid)) as PostishCopyAnnotationSnapshot[];

      return failureSnapshots.sort((a, b) => b.syncstamp - a.syncstamp);
    });

    const dataLoading = computed(() => copyJobLoading.value || copyAnnotationSnapshotsLoading.value || targetTitleLoading.value);
    const dataReady = computed(() => copyJob.value && copyAnnotationSnapshots.value && targetTitle.value);

    const state = computed<PageState>(() => {
      if (dataReady.value) {
        return {
          status: 'loaded',
          title: targetTitle.value,
          release: getReleaseLabel(copyJob.value!.source_title as PostishCopyTitleSnapshot, copyJob.value!.target_title as PostishCopyTitleSnapshot),
          successes: successes.value,
          failures: failures.value
        } as PageState;
      }

      if (dataLoading.value) {
        return {
          status: 'loading'
        };
      }

      return {
        status: 'error'
      };
    });

    return {
      hideNote,
      state
    };
  }
});
</script>

<style module>
.title {
  font-size: var(--fs-medium-head);
  font-weight: var(--fw-bold);
  margin-top: 1rem;
}

.warning {
  color: var(--c-notif-warning);
  background-color: hsla(var(--c-notif-warning), 0.02);
  border: 1px solid var(--c-notif-warning);
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 8px;
  display: grid;
  grid-template-areas:
    'icon message';
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: center;
}

.warning-icon {
  height: 1.5rem;
  width: 1.8rem;
  fill: var(--c-notif-warning);
  grid-area: icon;
}

.warning-text {
  grid-area: message;
}

.release {
  margin-block-start: 0.75rem;
}

.review-section:not(:first-of-type) {
  margin-block-start: 1rem;
}
.success-icon {
  height: 2rem;
  width: 2rem;
}

.success-icon g {
  stroke: var(--c-notif-success);
}

.failure-icon {
  height: 1.5rem;
  width: 1.5rem;

  stroke: var(--c-notif-error);
  fill: var(--c-notif-error);
}

</style>
