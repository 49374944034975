import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "visually-hidden" }
const _hoisted_2 = ["aria-live", "aria-busy"]
const _hoisted_3 = ["aria-live"]
const _hoisted_4 = { "aria-live": "assertive" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      "aria-live": _ctx.politeness,
      "aria-busy": _ctx.busy
    }, _toDisplayString(_ctx.resultCount), 9, _hoisted_2),
    _createElementVNode("div", { "aria-live": _ctx.politeness }, _toDisplayString(_ctx.singleMessage), 9, _hoisted_3),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.loadingMessage), 1)
  ]))
}